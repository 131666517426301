import React from 'react'
import AboutPageContent from '../components/AboutPageContent/AboutPageContent'
import OneColumnLayout from '../components/oneColumnLayout/OneColumnLayout'
import SEO from '../components/seo'

const About = () => {
  return (
    <OneColumnLayout>
      <SEO
        title="About Us"
        description="Information about the Cactus Atlas including
         who we are, what we do and future plans"
        url="https://cactusatlas.com/about/"
        type="article"
      />
      <AboutPageContent />
    </OneColumnLayout>
  )
}

export default About
