import React from 'react'
import cactusPeople from '../../images/cactus-people.jpg'
import MediaQuery from '../sharedComponents/MediaQuery/MediaQuery'
import { Parallax } from 'react-parallax'
import SocialMediaIcons from '../../components/sharedComponents/socialMediaIcons/SocialMediaIcons'
import styles from './about.module.css'

const queries = {
  mobile: '(max-width: 768px)',
  desktop: '(min-width: 769px)',
}

const ParallaxHero = () => (
  <div className={styles.heroBg}>
    <Parallax
      bgImage={cactusPeople}
      bgImageAlt="Glenn and Amy from the Cactus Atlas"
      strength={300}
      className={styles.heroImg}
    >
      <div className={styles.hero}>
        <div className={styles.about}>
          <h1 className={styles.title}>Howdy!</h1>
          <h3>We&apos;re Glenn and Amy! The team behind the Cactus Atlas.</h3>
        </div>
      </div>
      <div className={styles.heroSocialMedia}>
        <SocialMediaIcons space="50px" size="45px" />
      </div>
    </Parallax>
  </div>
)

const MobileHero = () => (
  <div className={`${styles.mobileHero} ${styles.hero}`}>
    <div className={styles.about}>
      <h1 className={styles.title}>Howdy!</h1>
      <h3 className={styles.mobileSubheading}>
        We&apos;re Glenn and Amy! The team behind the Cactus Atlas.
      </h3>
    </div>
  </div>
)

const AboutPageContent = () => {
  const matchPoints = MediaQuery(queries)
  return (
    <div className={styles.aboutPageWrapper}>
      <div>
        {matchPoints && matchPoints.mobile ? <MobileHero /> : null}
        {matchPoints && matchPoints.desktop ? <ParallaxHero /> : null}
      </div>
      <div className={styles.aboutUsContent}>
        <h2>About the Cactus Atlas Project</h2>
        <p>
          In the words of the late Jim Morrison of the Doors, &#34;The West is
          the Best&#34;, and we couldn&apos;t agree more! We live in the
          American Southwest and are fascinated with the landscapes, culture and
          history of this magical place.
        </p>
        <p>
          In 2019, we embarked on a mission to document and share our
          experiences in this region. Our goal is to entertain as well as
          inform. As you explore our content, you will discover that we blend
          the art of blogging with the immersion of vlogging in order to give
          you the most engaging experience possible.
        </p>

        <p>
          We also pull data into our website from multiple reputable sources in
          order to provide you with useful, up-to-date information in case you
          are planning that once-in-a-lifetime trip to the American Southwest,
          or perhaps a hiking trip in a remote wilderness.
        </p>
        <p>
          Our approach is to stay overnight at campsites and other interesting
          places like historic hotels, tour and review them, and then visit
          nearby things to do, taking you along for the ride of course!
        </p>
        <p>
          So, go grab yourself a cup of coffee or a cold sarsaparilla, sit back,
          and go adventuring with us!
        </p>
        <h2>Want to support the Cactus Atlas?</h2>
        <p>
          At this time, the best way that you can support us is to like and
          share our content on social media, subscribe to our YouTube channel,
          and tell your friends, family and social networks about us.
        </p>
        <p>
          Also, engage with us by dropping a comment or two on our YouTube
          channel. You can also shoot us an e-mail anytime if you have something
          to say or share! We love hearing from our fans!
        </p>
        <h2>What&apos;s Next?</h2>
        <p>
          We are busy adding new features to our website and filming new
          content. We are considering adding a forum or comments feature to our
          site, as well as translating our content into multiple languages in
          order to reach an international community.
        </p>
        <p>
          If you can think of a feature that you would enjoy seeing on our site,
          or perhaps a location that you would like us to visit, please send us
          an e-mail or write us a comment on our YouTube channel!
        </p>
      </div>
    </div>
  )
}

export default AboutPageContent
